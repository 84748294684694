<template>
  <section
    :class="['bleed-screen', props.gray ? 'bg-neutral-200' : 'bg-white']"
  >
    <div class="space-y-14 px-6 py-12 sm:space-y-20 sm:px-30 sm:py-20">
      <div
        v-if="
          $slots.heading || $slots.subheading || $slots.text || $slots.subtext
        "
      >
        <h2 v-if="$slots.heading" class="display-4xl sm:display-5.5xl">
          <slot name="heading" />
        </h2>

        <h3
          v-if="$slots.subheading"
          class="display-2xl sm:display-3xl pt-[60px]"
        >
          <slot name="subheading" />
        </h3>

        <div
          v-if="$slots.text"
          class="leading-7.5 pt-8 font-display text-xl font-medium -tracking-2"
        >
          <slot name="text" />
        </div>

        <div v-if="$slots.subtext" class="leading-7.5 pt-[60px] text-xl">
          <slot name="subtext" />
        </div>
      </div>

      <ul class="flex flex-wrap justify-between gap-y-9 sm:gap-y-15">
        <slot />
      </ul>

      <div
        v-if="$slots.textBottom"
        class="leading-7.5 !mt-[60px] font-display text-xl font-medium -tracking-2"
      >
        <slot name="textBottom" />
      </div>

      <div
        v-if="$slots.buttons"
        class="flex w-full flex-col md:flex-row md:justify-center"
      >
        <slot name="buttons" />
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
const props = defineProps<{
  gray?: boolean;
}>();

provide("bg-gray", props.gray);
</script>
